import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType, UserAD } from '@digi.me/models';
import { addIfObject, addIfProperty } from '@globals';
import { Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ValueSets } from '@hl7fhir/value-sets';
import { TEMPERATUURTYPE_CODELIJST } from '@hl7nl-fhir/value-sets';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { BodyTemperatureEntryComponent } from './body-temperature-entry/body-temperature-entry.component';
import { TEMPERATURE_TEMPLATE } from './body-temperature-entry/body-temperature.template';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-temperature',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
    SharedModule,
    BodyTemperatureEntryComponent,
  ],
  templateUrl: './self-measurements-modal-temperature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalTemperatureComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    temperatureControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
    methodControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  readonly temperatureTypeCodelist = TEMPERATUURTYPE_CODELIJST;
  readonly translatedTemperatureTypeCodelist: Coding[];

  private service = inject(SelfMeasurementsModalService);

  constructor(private readonly store: Store) {
    this.translatedTemperatureTypeCodelist = BodyTemperatureEntryComponent.temperatureTypeCodelistCodings.map(
      (coding) => {
        const display = ValueSets.getDisplay(coding, [TEMPERATUURTYPE_CODELIJST]);
        return { ...coding, display: display.code } as Coding;
      },
    );
  }

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (!this.form.value['temperatureControl']) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const temperature = this.form.value['temperatureControl'];
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;
    const method = this.form.value['methodControl'] ?? undefined;

    const observation: r3.Observation = {
      ...TEMPERATURE_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...TEMPERATURE_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...TEMPERATURE_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      valueQuantity: {
        ...TEMPERATURE_TEMPLATE.valueQuantity,
        value: parseFloat(temperature!),
      },
      ...addIfObject(!!method, {
        method: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: method,
              display: this.translatedTemperatureTypeCodelist.find((coding) => coding.code === method)?.display,
            },
          ],
        },
      }),
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: 'self-measurements',
        measurementType: MeasurementType.bodyTemperature,
      }),
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      temperatureControl: '',
      additionalCommentsControl: '',
      methodControl: '',
    });
  }
}
