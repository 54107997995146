import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { SELF_MEASUREMENT_ACTIONS } from '@store/app';
import { AUTHORIZE_URL_API_ACTIONS } from '@store/digi.me';
import { MenuService } from '../../menu';
import { AddDropDownMenuComponent } from './add-drop-down-menu.component';

@Component({
  standalone: true,
  selector: 'app-add-drop-down-menu-container',
  imports: [AddDropDownMenuComponent, SharedModule],
  template: `<app-add-drop-down-menu
    (linkSource)="linkSource()"
    (addMeasurement)="addMeasurement($event)"
    [menuItem]="menuService.selectMeasurementMenuItem$ | async"></app-add-drop-down-menu>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDropDownMenuContainerComponent {
  constructor(
    private readonly store: Store,
    readonly menuService: MenuService,
  ) {}

  linkSource(): void {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({ createNewUser: false, sourceFetch: true, sourceType: 'pull' }),
    );
  }

  addMeasurement(measurementType: string | undefined): void {
    this.store.dispatch(SELF_MEASUREMENT_ACTIONS.add({ measurementType: measurementType }));
  }
}
