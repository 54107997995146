import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType } from '@digi.me/models';
import { addIf, addIfProperty } from '@globals';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserAD } from '../digi.me/models/ad-user.model';
import { BodyHeightEntryComponent } from './body-height-entry/body-height-entry.component';
import { HEIGHT_TEMPLATE } from './body-height-entry/body-height.template';
import { WEIGHT_TEMPLATE } from './body-weight-entry/body-weight.template';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-height',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
    BodyHeightEntryComponent,
    SharedModule,
  ],
  templateUrl: './self-measurements-modal-height.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalHeightComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    heightControl: new FormControl('', Validators.required),
    unitControl: new FormControl('cm', Validators.required),
    positionControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(SelfMeasurementsModalService);

  constructor(private readonly store: Store) {}

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (!this.form.value['heightControl']) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const height = this.form.value['heightControl'];
    const unit = this.form.value['unitControl'] ?? undefined;
    const positionCode = this.form.value['positionControl'] ?? undefined;
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...HEIGHT_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...HEIGHT_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...HEIGHT_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      valueQuantity: {
        ...WEIGHT_TEMPLATE.valueQuantity,
        code: unit,
        unit,
        value: parseFloat(height!),
      },
      code: {
        coding: [
          ...HEIGHT_TEMPLATE.code.coding!,
          ...addIf(positionCode === '8308-9', {
            system: 'http://loinc.org',
            code: '8308-9',
            display: 'Body height standing',
          } as r3.Coding),
          ...addIf(positionCode === '8306-3', {
            system: 'http://loinc.org',
            code: '8308-9',
            display: 'Body height lying',
          } as r3.Coding),
        ],
      },
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: 'self-measurements',
        measurementType: MeasurementType.height,
      }),
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      heightControl: '',
      unitControl: 'cm',
      positionControl: '',
      additionalCommentsControl: '',
    });
  }
}
