<div ngbDropdown
     #drop="ngbDropdown"
     class="d-inline-block"
     placement="bottom-end">
  <app-button type="secondary"
              icon="add"
              class="caret-off"
              data-cy="help-menu-button"
              i18n-ariaLabel="@@app.ariaLabel.menu.add"
              ariaLabel="Add Measurement"
              ngbDropdownToggle>
  </app-button>
  <div ngbDropdownMenu
       class="dropdown-menu-end">
    <div class="p-3">
      <div class="d-grid gap-2">

        <app-button *ngIf="menuItem && menuItemEnabled"
                    type="secondary"
                    use="menu"
                    [svg]="menuItem.icon"
                    [label]="menuItem.header"
                    (clicked)="addMeasurement.emit(menuItem.icon); drop.close()"></app-button>

        <app-button type="secondary"
                    use="menu"
                    [svg]="'measurements'"
                    label="New Measurement"
                    i18n-label="@@nav.btn.add.measurement"
                    (clicked)="addMeasurement.emit(); drop.close()"></app-button>

        <hr class="w-75 delimeter">

        <app-button type="secondary"
                    use="menu"
                    [svg]="'sources'"
                    label="Add Records"
                    i18n-label="@@nav.btn.add.records"
                    (clicked)="linkSource.emit(); drop.close()"></app-button>

        <app-button type="secondary"
                    use="menu"
                    [svg]="'device'"
                    label="Add Device"
                    i18n-label="@@nav.btn.add.device"
                    [visible]="false"
                    (clicked)="drop.close()"></app-button>

      </div>
    </div>
  </div>
</div>

<app-self-measurements-modal></app-self-measurements-modal>
